import { Link } from "react-router-dom";
import styles from "./List.module.css";

interface ListItemProps {
  id: number;
  name: string;
  description: string;
  image_url: string;
}

const ListItem = (props: ListItemProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.image}>
        <img alt="beer" src={props.image_url} />
      </div>
      <div className={styles.content}>
        <div>{props.name}</div>
        <div className={styles.description}>{props.description}</div>{" "}
      </div>
      <div className={styles.link}>
        <Link to={`/${props.id}`}>⇨</Link>
      </div>
    </div>
  );
};

interface ListProps {
  items: Array<ListItemProps>;
}

const List = (props: ListProps) => {
  return (
    <div className={styles.list}>
      {props.items.map((el: ListItemProps) => (
        <ListItem
          key={el.id}
          id={el.id}
          name={el.name}
          description={el.description}
          image_url={el.image_url}
        />
      ))}
    </div>
  );
};

export default List;
