import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const API_ENDPOINT = "https://api.punkapi.com/v2/beers";
const SLICE_NAME = 'beers'
const RESULTS_PER_PAGE = 3

export interface Beer {
  id: number;
  name: string;
}

export const fetchData = createAsyncThunk(
  SLICE_NAME + "/list",
  async (query: object) => {
    const params = { ...query, per_page: RESULTS_PER_PAGE }
    const querystring: string = (Object.keys(params) as Array<keyof typeof params>).map(key => key + '=' + params[key]).join('&')
    const response = await fetch(API_ENDPOINT + '?' + querystring);
    const beers = await response.json();
    return { data: beers, query }
  }
);

export const fetchBeerById = createAsyncThunk(
  SLICE_NAME + "/beer",
  async (beerId: number) => {
    const response = await fetch(API_ENDPOINT + '/' + beerId);
    const data = await response.json();
    return data[0];
  }
);

interface BeersState {
  entities: Array<any>,
  selected: any,
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  query: { page?: number, beer_name?: string }
}

const beersSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    entities: [],
    selected: {},
    loading: 'idle',
    query: {}
  } as BeersState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        state.loading = 'succeeded'
        state.entities = payload.data
        state.query = payload.query
      })
      .addCase(fetchData.pending, (state) => { 
        state.loading = 'pending'
        state.entities = []
      })
      .addCase(fetchData.rejected, (state, { payload }) => { state.loading = 'failed' })
      .addCase(fetchBeerById.fulfilled, (state, { payload }) => {
        state.loading = 'succeeded'
        state.selected = payload
      })
      .addCase(fetchBeerById.pending, (state) => { 
        state.loading = 'pending'
        state.selected = {}
      })
      .addCase(fetchBeerById.rejected, (state, { payload }) => { state.loading = 'failed' })
  },
})

export default beersSlice.reducer