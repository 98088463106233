import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Random.module.css";

const RANDOM_MIN = 1;
const RANDOM_MAX = 50;

const Random = () => {
  const [id, setId] = useState(1);

  useEffect(() => {
    generateRandom();
  }, []);

  const generateRandom = () => {
    function randomInt(min: number, max: number) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    let random = randomInt(RANDOM_MIN, RANDOM_MAX);
    setId(random);
  };

  return (
    <div className={styles.random}>
      <Link to={`/${id}`}>Random</Link>
    </div>
  );
};

export default Random;
