import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { fetchBeerById } from "../redux/beerSlice";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import styles from "./Page.module.css";

const Page = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { loading, selected } = useSelector((state: RootState) => state.beers);

  useEffect(() => {
    dispatch(fetchBeerById(parseInt(id)));
  }, [dispatch, id]);

  return (
    <div className={styles.container}>
      {loading === "pending" ? (
        <Loading />
      ) : (
        <>
          <div className={styles.image}>
            <img src={selected.image_url} alt="beer" />
          </div>
          <div className={styles.content}>
            <h2 className={styles.name}>
              {selected.name} - {selected.tagline}
            </h2>
            <div className={styles.description}>
              <p>first brewed: {selected.first_brewed}</p>
              <p>ibu: {selected.ibu}</p>
              <p>abv: {selected.abv}</p>
              <p>{selected.description}</p>
            </div>
            <div className={styles.link}>
              <Link to="/">« go back</Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Page;
