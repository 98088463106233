import { useState, useEffect } from "react";
import { fetchData } from "../redux/beerSlice";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Search.module.css";

const Search = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: RootState) => state.beers);
  const [name, setName] = useState("");

  useEffect(() => {
    if (query.beer_name) setName(query.beer_name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(fetchData({ page: 1, ...(name && { beer_name: name }) }));
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value;
    setName(value);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        placeholder="Search"
        onChange={handleInputChange}
      />
    </form>
  );
};

export default Search;
