import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { fetchData } from "../redux/beerSlice";
import List from "./List";
import Pagination from "./Pagination";
import Random from "./Random";
import Search from "./Search";
import Loading from "./Loading";
import styles from "./Home.module.css";

const Home = () => {
  const dispatch = useDispatch();
  const { loading, entities, query } = useSelector(
    (state: RootState) => state.beers
  );

  useEffect(() => {
    let name = query.beer_name ? query.beer_name : "";
    let page = query.page ? query.page : 1;
    if (!entities.length)
      dispatch(fetchData({ page, ...(name && { beer_name: name }) }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Search />
        <Random />
      </div>
      {loading === "pending" ? (
        <Loading />
      ) : (
        <>
          <List items={entities} />
          <Pagination />
        </>
      )}
    </div>
  );
};

export default Home;
