import { fetchData } from "../redux/beerSlice";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Pagination.module.css";

const Pagination = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: RootState) => state.beers);
  const pagesAvailable = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleClick = (page: number) => {
    dispatch(
      fetchData({
        page,
        ...(query.beer_name && { beer_name: query.beer_name }),
      })
    );
  };

  const currentPage = query.page || 1;
  const prevPage = currentPage > 1 ? currentPage - 1 : 1;
  const nextPage =
    currentPage < pagesAvailable.length
      ? currentPage + 1
      : pagesAvailable[pagesAvailable.length - 1];

  return (
    <div className={styles.container}>
      <ul>
        <li className={styles.prev} onClick={() => handleClick(prevPage)}>
          ⇦
        </li>
        {pagesAvailable.map((number) => (
          <li
            className={styles.element}
            key={number}
            onClick={() => handleClick(number)}
          >
            {number === currentPage ? <strong>{number}</strong> : number}
          </li>
        ))}
        <li className={styles.next} onClick={() => handleClick(nextPage)}>
          ⇨
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
